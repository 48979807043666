.navbar-icon-top .navbar-nav .nav-link>.fa {
    position: relative;
    width: 36px;
    font-size: 24px;
}

.navbar-icon-top .navbar-nav .nav-link>.fas {
    position: relative;
    width: 36px;
    font-size: 24px;
}

.navbar-icon-top .navbar-nav .nav-link>.fa>.badge {
    font-size: 0.65rem;
    position: absolute;
    right: 0;
    font-family: sans-serif;
}

.navbar-icon-top .navbar-nav .nav-link>.fas>.badge {
    font-size: 0.65rem;
    position: absolute;
    right: 0;
    font-family: sans-serif;
}

.navbar-icon-top .navbar-nav .nav-link>.fa {
    top: 3px;
    line-height: 12px;
    margin-right: 5px;
}

.navbar-icon-top .navbar-nav .nav-link>.fas {
    top: 3px;
    line-height: 12px;
    margin-right: 5px;
}

.navbar-icon-top .navbar-nav .nav-link>.fa>.badge {
    top: -10px;
}

.navbar-icon-top .navbar-nav .nav-link>.fas>.badge {
    top: -10px;
}

@media (min-width: 576px) {
    .navbar-icon-top.navbar-expand-sm .navbar-nav .nav-link {
        text-align: center;
        display: table-cell;
        height: 70px;
        vertical-align: middle;
        padding-top: 0;
        padding-bottom: 0;
    }
    .navbar-icon-top.navbar-expand-sm .navbar-nav .nav-link>.fa {
        display: block;
        width: 48px;
        margin: 2px auto 4px auto;
        top: 0;
        line-height: 24px;
    }
    .navbar-icon-top.navbar-expand-sm .navbar-nav .nav-link>.fa>.badge {
        top: -7px;
    }
    .navbar-icon-top.navbar-expand-sm .navbar-nav .nav-link>.fas {
        display: block;
        width: 48px;
        margin: 2px auto 4px auto;
        top: 0;
        line-height: 24px;
    }
    .navbar-icon-top.navbar-expand-sm .navbar-nav .nav-link>.fas>.badge {
        top: -7px;
    }
}

@media (min-width: 768px) {
    .navbar-icon-top.navbar-expand-md .navbar-nav .nav-link {
        text-align: center;
        display: table-cell;
        height: 70px;
        vertical-align: middle;
        padding-top: 0;
        padding-bottom: 0;
    }
    .navbar-icon-top.navbar-expand-md .navbar-nav .nav-link>.fa {
        display: block;
        width: 48px;
        margin: 2px auto 4px auto;
        top: 0;
        line-height: 24px;
    }
    .navbar-icon-top.navbar-expand-md .navbar-nav .nav-link>.fa>.badge {
        top: -7px;
    }
    .navbar-icon-top.navbar-expand-md .navbar-nav .nav-link>.fas {
        display: block;
        width: 48px;
        margin: 2px auto 4px auto;
        top: 0;
        line-height: 24px;
    }
    .navbar-icon-top.navbar-expand-md .navbar-nav .nav-link>.fas>.badge {
        top: -7px;
    }
}

@media (min-width: 992px) {
    .navbar-icon-top.navbar-expand-lg .navbar-nav .nav-link {
        text-align: center;
        display: table-cell;
        height: 70px;
        vertical-align: middle;
        padding-top: 0;
        padding-bottom: 0;
    }
    .navbar-icon-top.navbar-expand-lg .navbar-nav .nav-link>.fa {
        display: block;
        width: 48px;
        margin: 2px auto 4px auto;
        top: 0;
        line-height: 24px;
    }
    .navbar-icon-top.navbar-expand-lg .navbar-nav .nav-link>.fa>.badge {
        top: -7px;
    }
    .navbar-icon-top.navbar-expand-lg .navbar-nav .nav-link>.fas {
        display: block;
        width: 48px;
        margin: 2px auto 4px auto;
        top: 0;
        line-height: 24px;
    }
    .navbar-icon-top.navbar-expand-lg .navbar-nav .nav-link>.fas>.badge {
        top: -7px;
    }
}

@media (min-width: 1200px) {
    .navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link {
        text-align: center;
        display: table-cell;
        height: 70px;
        vertical-align: middle;
        padding-top: 0;
        padding-bottom: 0;
    }
    .navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link>.fa {
        display: block;
        width: 48px;
        margin: 2px auto 4px auto;
        top: 0;
        line-height: 24px;
    }
    .navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link>.fa>.badge {
        top: -7px;
    }
    .navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link>.fas {
        display: block;
        width: 48px;
        margin: 2px auto 4px auto;
        top: 0;
        line-height: 24px;
    }
    .navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link>.fas>.badge {
        top: -7px;
    }
}